import classes from './MainHeader.module.css';

function MainHeader() {
    return (
        <div className={classes.header}>
            <p>John, Mac & Co</p>
        </div>
    );
}

export default MainHeader;
//            <h1>Tennis Time</h1>