import { useState, useEffect, useRef } from 'react';

import PlayerCategories from './PlayerCategories';
import * as constDefs from '../constants/constants';
import {getPlayersAvailability, updatePlayers } from '../dataAccess/getData';
import classes from './PlayersAvailability.module.css';

//Component
function PlayersAvailability(props) {
    const [loadedPlayersAvailability, setLoadedPlayersAvailability] = useState();
    const [isLoading, setIsLoading] = useState(false);

    function getPlayersAvailabilityForGenderAndLevel(players, gender, level) {
        return players.filter((player) => player.gender === gender && player.rating === level);
    };

    function getPlayersAvailabilitySorted(players) {
        let sortedPlayers = players.sort((a,b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
        return sortedPlayers;
    };

    function updateAvailabilityHandler(e) {
        let playerId = e.target.getAttribute("data-playerid");
        let matchId = e.target.getAttribute("data-matchid");
        let availability = e.target.getAttribute("data-available");

        const updatedPlayers = loadedPlayersAvailability.map(player => {
            if (player.id === playerId) {
                let updatedMatches = player.matches.map(match => {
                    if (match.matchId === matchId) {
                        return {
                            ...match,
                            availability: match.availability = availability
                        };
                    }
                    else {
                        return match;
                    }
                })
                return {
                    ...player,
                    matches: updatedMatches
                }
            } else {
                return player;
            }
        });

        // POST 
        try {
            let returnVal = updatePlayers(updatedPlayers, props.onError);
            // Update state
            setLoadedPlayersAvailability(updatedPlayers); 
        }
        catch(error) {
            props.onError(error);
        }
    }

    // useEffect to retrieve Players and Availability
    useEffect(() => {
            setIsLoading(true);

            getPlayersAvailability(props.onError)
                .then((playersAvailability) => {
                    setLoadedPlayersAvailability(playersAvailability) 
                }).catch(error => {
                    let errMsg = "ERROR: getPlayersAvailability: " + error;
                    console.log(errMsg);
                    props.onError(errMsg);
                });

            setIsLoading(false);
    }, []);


    // Begin content setup
    let content = <p>No players found.</p>;

    if (isLoading) {
        content = <p>Loading...</p>;
    }

    // Set up content for rendering
    if (loadedPlayersAvailability) {

       let playersSorted = getPlayersAvailabilitySorted(loadedPlayersAvailability);

        content = (
            <div className={classes.levelPlayerBlock}> 
                {playersSorted.map((player) => (
                    <div key={player.id}>
                        <PlayerCategories readOnly={props.readOnly} playerInfo={player} matchList={props.matchList} onUpdateAvailability={updateAvailabilityHandler}  />
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className={classes.playersLayout}> 
            {content}
        </div>
    ); 
}

export default PlayersAvailability;
