import classes from './MatchInfo.module.css';

function MatchInfo({matchInfo}) {
    return (
        <div> 
            <div className={classes.title}>
                {matchInfo.match.date}
            </div>
            <div>
                {matchInfo.match.dayOfWeek}
            </div>
            <div>
                {matchInfo.match.time}
            </div>
            <div>
                {matchInfo.match.location}
            </div>
            <div>
                {matchInfo.match.opponent}
            </div>
        </div>
    ); 
}

export default MatchInfo;

//<div className={[classes.padding1em, classes.boldFont].join(' ')}>Date</div>
//<div className={classes.padding1em}>Time</div>
/*            <div className={classes.title}>
                Match {matchInfo.match.id}
            </div>
            */