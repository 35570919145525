import { createBrowserRouter, RouterProvider } from 'react-router-dom';
//import { BrowserRouter, Routes, Route } from 'react-router-dom';

//import * as routeLinks from './routes/routes';
import Layout from './components/shared/Layout';
//import MainHeader from './components/shared/MainHeader';
import Root from './pages/Root';
import Availability, {loader as matchesLoader} from  './pages/Availability';
import Lineup from './pages/Lineup'; 
import LineupAdmin from './pages/LineupAdmin'; 
import AvailableError from './components/shared/AvailableError';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <AvailableError />,
    children: [
      {
        path: '/',
        element: <Layout />,
        children: [
          { path: '/availability', element: <Availability />, errorElement: <AvailableError />, loader: matchesLoader},
          { path: '/lineup', element: <Lineup />},
          { path: '/lineupadmin', element: <LineupAdmin />},
        ]
      },
      //{ index: true, element: <MainHeader />},
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}


/*
function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path={routeLinks.availabilityRoute} element={<Availability />} />
          <Route path={routeLinks.lineupRoute} element={<Lineup />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}
*/

export default App;
