import React, { useState } from 'react';
import classes from './Pass.module.css';

function Pass(props) {
    const [inputValue, setInputValue] = useState('');

    const handleClick = () => {
        //console.log(inputValue);
        props.onSubmitPassKey(inputValue);
        setInputValue("");
    };

    let passContent = "";
    passContent = (
        <div >
           <input type="password" className={classes.passInput} value={inputValue} onChange={(e) => setInputValue(e.target.value)}></input>
           <input type="button" className={classes.passButton} id="passkeybtn" name="passkeybtn" onClick={handleClick}></input>
        </div>
        );

    return (
        <div>
            {passContent}
        </div>
    );
}

export default Pass;