import { useRouteError } from 'react-router-dom';
import classes from './AvailableError.module.css';

function AvailableError() {

    const error = useRouteError();

    return (
        <div className={classes.errorMessage}>
            <h1>Oh no!</h1>
            <p>An Error Occurred.</p>
            <p>{error.message}</p>
        </div>
    );
}

export default AvailableError;