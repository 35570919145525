import { Outlet } from "react-router-dom";

import MainHeader from "../components/shared/MainHeader";
import MainNavigation from "../components/shared/MainNavigation";
import Footer from "../components/shared/Footer";

function Root() {
    return (
        <>
            <header>
                <MainHeader />
            </header>
            <div>
                <MainNavigation />
            </div>
            <Outlet />
            <div>
                <Footer />
            </div>
        </>
    );
}

export default Root;