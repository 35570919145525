import classes from './ErrorMsgHandling.module.css';

function ErrorMsgHandling(props) {

    let errorContent = "";
    if (props.errorMessage) {
        errorContent = (
        <div className={classes.errorMessage}>
            {props.errorMessage}
        </div>
        );
    }

    return (
        <div>
            {errorContent}
        </div>
    );
}

export default ErrorMsgHandling;