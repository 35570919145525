import React from 'react';
import classes from './ModalGroupMe.module.css';

function ModalGroupMe(props) {
    let modalGroupMe = "";
    if (props.showModal) {
        modalGroupMe = (
            <div className={classes.modalGroupMe} >
                <div className={classes.modalGroupMeContent}>
                    <div className={classes.closeButton} onClick={props.setShowModal}>
                        Close 
                    </div>
                    <div className={classes.groupMeHeadingText}>
                        Enter GroupMe message below. 
                    </div>
                    <form className={classes.groupMeTextArea} method="post" onSubmit={props.submitToGroupMe}>
                        <textarea className={classes.groupMeContextArea} name="groupMeContent" id="groupMeContentArea" rows="10" value={props.textAreaValue} onChange={props.handleTextAreaChange} ></textarea>
                        <button className={classes.sendGroupMeButton} data-matchid={props.matchInfo.id} type="submit">SEND</button>
                    </form>
                </div>

            </div>
        );
    }

    return (
        <div>
            {modalGroupMe}
        </div>
    );
}

export default ModalGroupMe;
