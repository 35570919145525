//import MatchInfo from '../components/MatchInfo';
import { useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { getMatches } from '../dataAccess/getData';
import {getPermissionLevel } from '../dataAccess/getData';

import Matches from '../components/Matches';
import PlayersAvailability from '../components/PlayersAvailability';
import Pass from '../components/Pass.js'; 
import * as constDefs from '../constants/constants';
import classes from './Availability.module.css';
import ErrorMsgHandling from '../components/shared/ErrorMsgHandling'; 

function Availability() {
    const [error, setError] = useState('');
    const [readOnly, setReadOnly] = useState(true);
    const loadedMatches = useLoaderData();

    function updateErrorHandler(errorMsg) {
        setError(errorMsg);
    }

    // Get Permission Level
    function submitPassKeyHandler(keyPassVal) {
        let keyPassObj = {"readwrite" : keyPassVal};
        getPermissionLevel(keyPassObj)
            .then((returnVal) => {
                if (returnVal.readwrite === constDefs.keyValuePass) {
                    setReadOnly(false) 
                } else {
                    setReadOnly(true)
                }
            }).catch(error => {
                let errMsg = "ERROR: getPermissionLevel: " + error;
                setError(errMsg);
            });
    }

    if (!Array.isArray(loadedMatches)) {
        if (loadedMatches.startsWith("ERROR") ) {
            //Note: The following updateErrorHandler call produces an infinite loop. Don't use it like this.
            //      Instead, use the errorElement property provided with React Router.
            //updateErrorHandler(loadedMatches);
            throw new Error("Error from Availability component - " + loadedMatches);
        }
    }

    let content = (
        <div>
            <div className={classes.availabilityLayout}>
                <ErrorMsgHandling errorMessage={error} />
                <Matches matchList={loadedMatches} />
                <PlayersAvailability onError={updateErrorHandler} readOnly={readOnly} matchList={loadedMatches}  />
            </div>
            <Pass onSubmitPassKey={submitPassKeyHandler} />
        </div>
        )

    return (
        <div>
            {content}
        </div>
    ); 
}

export default Availability;

export function loader() {
    const response = getMatches()
        .then((matches) => {
            return matches;
        })
        .catch(error => {
            let errMsg = "ERROR: getMatches: " + error;
            console.log(errMsg);
            return errMsg; 
        });
    return response;
}