import * as constDefs from '../constants/constants';

// NOTE: This function is only for testing locally
function constructMatchAvailability(matches) {
    var matchesArray = [];

    matches.forEach(function(match) {
        var matchObj = {
            "id": match.id,
            "title": match.title,
            "date": match.date,
            "dayOfWeek": match.dayOfWeek,
            "time": match.time,
            "place": match.place,
            "opponent": match.opponent,
            "available": match.available,
            //"available": "mystery"
            //"available": "yes"
        };

        matchesArray.push(matchObj);
    })

    return matchesArray;
};

// NOTE: This function is only for testing locally
export function getPlayerMatchesForTest(players) {
    let PlayerMatchesAvailableArray = [];
        players.forEach(function(player) {
        //var playerInfo = new PlayerAvailClass(player);
        var playerInfo = {
            "id": player.id,
            "name": player.name,
            "gender": player.gender,
            "rating": player.rating
        }
        var matchAvail = constructMatchAvailability(constDefs.tennisMatchArray); 
        playerInfo["matches"] = matchAvail;
        PlayerMatchesAvailableArray.push(playerInfo);
    }); 

    return PlayerMatchesAvailableArray; 
}


// NOTE: This function is only for testing locally
function getPlayersAvailabilityList () {
    let PlayerMatchesAvailableArray = [];
    constDefs.PlayersArray.forEach(function(player) {
        //var playerInfo = new PlayerAvailClass(player);
        var playerInfo = {
            "id": player.id,
            "name": player.name,
            "gender": player.gender,
            "rating": player.rating
        }
        var matchAvail = constructMatchAvailability(constDefs.tennisMatchArray); 
        playerInfo["matches"] = matchAvail;
        PlayerMatchesAvailableArray.push(playerInfo);
    }); 

    return PlayerMatchesAvailableArray; 
}

export default getPlayersAvailabilityList;