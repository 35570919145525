import * as constDefs from '../constants/constants';
import getPlayersAvailabilityList from './getDataTest';
import { compare } from '../shared';

export async function getMatches() {
    if (constDefs.env ===  constDefs.envTest) {
       const responseData = await fetch(constDefs.getMatchesUrl)
            .then(async response => {
                const data = await response.json();
                //Check for error response
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                //Return data if no error
                //return data.sort(compare);
                return data;
            })
            .catch(error => {
                let errMsg = "ERROR: " + error;
                console.log(errMsg);
                return Promise.reject(errMsg);
            });

        return responseData;
        

    //    if (!responseData.ok) {
    //         //throw new Error('Fetching matches failed');
    //         const error =  "An error occurred in getMatches";
    //         //updateErrorHandling(error);
    //         return Promise.reject(error);
    //    }
    //    const response = await responseData.json();
    //    return response.sort(compare);
    }

    if (constDefs.env === constDefs.envLocalTest) {
        const response = constDefs.tennisMatchArray;
        return response.sort(compare);
    }
}

export async function getPlayersAvailability(onError) {
    if (constDefs.env ===  constDefs.envTest) {
        const responseData = await fetch(constDefs.getPlayersUrl)
        .then(async response => {
            const data = await response.json();
            // Check for error response
            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // Return data if no error
            return data.Items; 
        })
        .catch(error => {
            let errMsg = "ERROR: " + error;
            console.log(errMsg);
            //onError(errMsg);
            return Promise.reject(errMsg);
        });

        return responseData;


        //const responseData = await fetch(constDefs.getPlayersUrl);
        // if (!responseData.ok) {
        //     //throw new Error('Fetching players failed');
        //     const error =  "ERROR: An error occurred in getPlayersAvailability";
        //     //updateErrorHandling(error);
        //     return Promise.reject(error);
        // }
        // const response = await responseData.json();
        // return response.Items;



        //return response;
        /*** TESTING */
        //let testItems = getPlayerMatchesForTest(response.Items);
        //return testItems;
    }

    if (constDefs.env === constDefs.envLocalTest) {
        const playersAvailabilityList = getPlayersAvailabilityList();
        return playersAvailabilityList;
    }
}

//export async function updatePlayers(players) {
export function updatePlayers(players, onError) {

    const playersData = JSON.stringify(players);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: playersData 
    };

    if (constDefs.env ===  constDefs.envTest) {
        fetch(constDefs.postPlayersUrl, requestOptions) 
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json(); 

                //check for error response
                if (!response.ok) {
                    //get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    //updateErrorHandling(error);
                    return Promise.reject(error);
                }

                return response;
            })
            .catch(error => {
                let errMsg = "ERROR: updatePlayers: " + error;
                console.error(errMsg);
                //throw new Error('Fetching matches failed' + error);
                //return ("Error: " + error);
                onError(errMsg);
                //return Promise.reject(error);
            })
     }

 
     if (constDefs.env === constDefs.envLocalTest) {
        return playersData;
     }

}

export async function getPlayers(onError) {
    if (constDefs.env ===  constDefs.envTest) {
        const responseData = await fetch(constDefs.getPlayersUrl)
        .then(async response => {
            const data = await response.json();
            // Check for error response
            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // Return data if no error
            return data.Items; 
        })
        .catch(error => {
            let errMsg = "ERROR: getPlayers: " + error;
            console.log(errMsg);
            //onError(errMsg);
            //return Promise.reject(errMsg);
            onError(errMsg);
        });

        return responseData;


        //const responseData = await fetch(constDefs.getPlayersUrl);
        // if (!responseData.ok) {
        //     //throw new Error('Fetching players failed');
        //     const error =  "ERROR: An error occurred in getPlayersAvailability";
        //     //updateErrorHandling(error);
        //     return Promise.reject(error);
        // }
        // const response = await responseData.json();
        // return response.Items;



        //return response;
        /*** TESTING */
        //let testItems = getPlayerMatchesForTest(response.Items);
        //return testItems;
    }

    if (constDefs.env === constDefs.envLocalTest) {
        const playersAvailabilityList = getPlayersAvailabilityList();
        return playersAvailabilityList;
    }
}

export function updateMatches(matches, onError) {

    const matchesData = JSON.stringify(matches);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: matchesData 
    };

    if (constDefs.env ===  constDefs.envTest) {
        fetch(constDefs.postMatchesUrl, requestOptions) 
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json(); 

                //check for error response
                if (!response.ok) {
                    //get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    //updateErrorHandling(error);
                    return Promise.reject(error);
                }

                return response;
            })
            .catch(error => {
                let errMsg = "ERROR: updateMatches: " + error;
                console.error(errMsg);
                //throw new Error('Fetching matches failed' + error);
                //return ("Error: " + error);
                //onError(errMsg);
                return Promise.reject(error);
            })
     }

     /*
     if (constDefs.env === constDefs.envLocalTest) {
        return playersData;
     }
     */

}

export async function getPermissionLevel(keyPassVal, onError) {
    const keyPassValData = JSON.stringify(keyPassVal);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: keyPassValData
    };

    const responseData = await fetch(constDefs.getPermissionLevelUrl, requestOptions) 
            .then(async response => {
                const data = await response.json(); 

                //check for error response
                if (!response.ok) {
                    //get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    //updateErrorHandling(error);
                    return Promise.reject(error);
                }
                // Return data if no error
                return data;
            })
            .catch(error => {
                let errMsg = "ERROR: updateMatches: " + error;
                console.error(errMsg);
                return Promise.reject(error);
            })
    return responseData;
}


export async function postToGroupMe(matchObj, onError) {

    const match = JSON.stringify(matchObj);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: match 
    };

    if (constDefs.env ===  constDefs.envTest) {
        const responseData = await fetch(constDefs.postGroupMeMsg, requestOptions) 
            .then(async response => {
                const data = await response.json(); 

                //check for error response
                if (!response.ok) {
                    //get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                //Return data if no error
                return data;
            })
            .catch(error => {
                let errMsg = "ERROR: postingToGroupMe: " + error;
                console.error(errMsg);
                return Promise.reject(error);
            });

        return responseData;
     }

}