import classes from './PlayerInfo.module.css';

function PlayerInfo({playerInfo}) {

    return (
        <div className={classes.playerName}>
            {playerInfo}
        </div>
    ); 
}

export default PlayerInfo;
