import * as constDefs from '../constants/constants';
import classes from './PlayerList.module.css';

function PlayerList(props) {

    let playerListRowClass = classes.playerListRow;
    let playerNameClass = classes.playerName;
    let draggableFlag = false;

    props.playerInfo.matches.forEach(function(match) {
        if (((props.activeMatch) && (props.activeMatch.length > 0) && (match.matchId === props.activeMatch[0].id)) && (match.availability === constDefs.availYes )) {
            playerListRowClass = `${classes.playerListRow} ${classes.playerActive}`; 
            playerNameClass = `${classes.playerName} ${classes.playerNameActive}`;
            draggableFlag = true;
        }
    })

    // function allowDrop() {
    //     //props.allowDrop(ev);
    //     console.log("allowDrop in PlayerList");
    // }

    // function drag(ev) {
    //     //ev.preventDefault();
    //     console.log("drag in PlayerList");
    //     var x = ev.target.id;
    //     console.log(x);
    // }

    // function drop() {
    //     //ev.preventDefault();
    //     console.log("drop in PlayerList");
    // }
 
    return (
        <div className={`${playerListRowClass}`} id={props.playerInfo.id}  >
            <div className={`${playerNameClass}`} draggable={draggableFlag} onDragStart={props.drag} id={props.playerInfo.id}>
                {props.playerInfo.name}
            </div>
        </div>
    ); 
}

export default PlayerList;