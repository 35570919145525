import classes from './MatchInfoShort.module.css';

function MatchInfoShort(props) {

    let matchInfoStuffClass = classes.matchInfoStuff;
    if (props.isActive) {
       matchInfoStuffClass = `${classes.matchInfoStuff} ${classes.matchInfoStuffActive}`;
    }

    return (
        <div data-matchid={props.matchInfo.id} onClick={props.setMatchActive}> 
            <button className={`${matchInfoStuffClass}`} data-matchid={props.matchInfo.id} onClick={props.setMatchActive}>
                <div data-matchid={props.matchInfo.id}>
                    {props.matchInfo.date}
                </div>
                <div data-matchid={props.matchInfo.id}>
                    vs. {props.matchInfo.opponent}
                </div>
                <div data-matchid={props.matchInfo.id}>
                    @{props.matchInfo.location}
                </div>
            </button>
        </div>
    ); 
}

export default MatchInfoShort;
