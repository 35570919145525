import { useState, useEffect } from 'react';
import classes from './LineupMatch.module.css';
import CourtAssign from './CourtAssign';
import MatchInfoShort from './MatchInfoShort';
import ModalGroupMe from './ModalGroupMe';

function LineupMatch(props) {
    const [showGroupMe, setShowGroupMe] = useState(false);
    const [textAreaValue, setTextAreaValue] = useState("");

    let matchCardClass = classes.matchCard;

    if (props.isActive) {
       matchCardClass = `${classes.matchCard} ${classes.matchCardActive}`;
    }
    if (props.readOnly) {
       matchCardClass = `${classes.matchCardRO} ${classes.matchCardActive}`;
    }

    let unsortedMatchAssign = props.matchInfo.match_assignments;

    // Sort the match_assignments object
    let sortedMatchAssign = Object.keys(unsortedMatchAssign)
        .sort()
        .reduce((tempObj, key) => {
            tempObj[key] = unsortedMatchAssign[key];
            return tempObj;
        }, {});

    // Make an array from the match_assignments sorted object
    let sortedMatchArray = Object.keys(sortedMatchAssign).map((key) => {
        return [key, sortedMatchAssign[key]];
    });

    function onToggleShowGroupMe(e) {
        setShowGroupMe(!showGroupMe);
    }

    function handleTextAreaChange(e) {
        setTextAreaValue(e.target.value);
    }

    function submitToGroupMe(e) {
        e.preventDefault();
        props.onPostToGroupMe(props.matchInfo.id, textAreaValue);
        setTextAreaValue("");
        setShowGroupMe(false);
    }

    // NOTE: groupCourts is unused at this point.
    function groupCourts(court) {
        let courtNumber;
        switch(court) {
            case "court1":
                courtNumber = "Court1";
                break;
            case "court2":
                courtNumber = "Court2";
                break;
            case "court3":
                courtNumber = "Court3";
                break;
            default:
                break;
        }
        return courtNumber;
    }

    // NOTE: filterCourts is unused at this point.
    function filterCourts(court) {
        let keyCourt = Object.keys(court);
        let subCourt = keyCourt[0].substring(0,6); 
        var x = groupCourts(subCourt);
        return x;
    }

    // Now, lets group the courts by court number, and place into arrays, 
    // then put those arrays into an overall array, so that we can display 
    // properly on the page. Yay React, piece of shit at this point in my life.
    let courtAssignArray = [];
    let tmpValue = "";
    let tmpArr = [];
    for (let j = 0; j < sortedMatchArray.length; j++) {
        let a = sortedMatchArray[j][0].substring(0,6);
        if (j === 0) {
            tmpValue = a;
        }
        if (a === tmpValue) {
            tmpArr.push(sortedMatchArray[j]);
        } else {
            courtAssignArray.push(tmpArr);
            tmpArr = [];
            tmpArr.push(sortedMatchArray[j]);
        }
        tmpValue = a;
    }

    if (tmpArr.length > 0) {
        courtAssignArray.push(tmpArr);
        tmpArr = [];
    }

    // Create Court Assign content.
    let courtContent = "";
    courtContent = (
        <div>
            {courtAssignArray.map((court) => {
                return (
                    <div key={court}>
                        <CourtAssign 
                            matchInfo={props.matchInfo} 
                            court={court} 
                            changedPlayer={props.changedPlayer} 
                            getPlayerNameById={props.getPlayerNameById}
                            readOnly={props.readOnly}
                            onSetAllowDrop={props.onSetAllowDrop} 
                            onSetOnDrop={props.onSetOnDrop} 
                            onSetRemovePlayer={props.onSetRemovePlayer}
                            /> 
                    </div>
                )
            })
            }
        </div>
    )

    // Create GroupMe content
    let groupMeContent = "";
    if (showGroupMe) {
        groupMeContent = (
            <div>
                <ModalGroupMe 
                    showModal={showGroupMe} 
                    setShowModal={onToggleShowGroupMe} 
                    submitToGroupMe={submitToGroupMe} 
                    matchInfo={props.matchInfo}
                    textAreaValue={textAreaValue} 
                    handleTextAreaChange={handleTextAreaChange} />
            </div>
        )
    }

    // Create all content.
    let content = "";
    if (props.readOnly) {
        content = (
                <div className={`${matchCardClass}`}> 
                    <div className={classes.displayMatchCardTopRO}>
                        <div className={classes.buttonMatchInfoRO}>
                            <MatchInfoShort 
                                matchInfo={props.matchInfo} 
                                data-matchid={props.matchInfo.id} 
                                isActive={props.isActive}
                                readOnly={props.readOnly}
                                setMatchActive={props.onSetMatchActive} />
                        </div>
                    </div>
                    <div>
                        {courtContent}
                    </div>
                </div>
        )
    } else {
        content = (
                <div className={`${matchCardClass}`}> 
                    <div className={classes.displayMatchCardTop}>
                        <div className={classes.buttonMatchInfo}>
                            <MatchInfoShort 
                                matchInfo={props.matchInfo} 
                                data-matchid={props.matchInfo.id} 
                                isActive={props.isActive}
                                readOnly={props.readOnly}
                                setMatchActive={props.onSetMatchActive} />
                        </div>
                        <div className={classes.buttonGroupMe} data-matchid={props.matchInfo.id} onClick={onToggleShowGroupMe}>
                            Write to GroupMe 
                        </div>
                    </div>
                    <div>
                        {groupMeContent}
                    </div>
                    <div className={classes.bottomBorder}></div>
                    <div>
                        {courtContent}
                    </div>
                </div>
        )
    }

    return (
        <div>
            {content}
        </div>
    ); 
}

export default LineupMatch;
