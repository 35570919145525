import { useState, useEffect } from 'react';
import {getPlayers } from '../dataAccess/getData';
import {getPermissionLevel } from '../dataAccess/getData';

import PlayerList from '../components/PlayerList';
import LineupMatches from '../components/LineupMatches';
import Pass from '../components/Pass.js'; 
import * as constDefs from '../constants/constants';
import classes from './Lineup.module.css';

//Component
function Lineup(props) {
    const [error, setError] = useState('');
    const [players, setPlayers] = useState([]);
    const [activeMatch, setActiveMatch] = useState(null);
    const [changedPlayer, setChangedPlayer] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [readOnly, setReadOnly] = useState(true);
    const [hasRead, setHasRead] = useState(true);


    function getPlayersForGenderAndLevel(players, gender, level) {
        return players.filter((player) => player.gender === gender && player.rating === level);
    }

    function getPlayerById(id) {
        let foundPlayer = players.filter((player) => player.id === id);
        if (foundPlayer.length > 0) {
            return foundPlayer;
        }
        else {
            return "";
        }
    }

    function getPlayerNameById(id) {
        let foundPlayer = players.filter((player) => player.id === id);
        if (foundPlayer.length > 0) {
            return foundPlayer[0].name
        }
        else {
            return "";
        }
    }

    function setChangedPlayerHandler(playerId) {
        var chPlayer = "";
        if (activeMatch) {
            chPlayer = activeMatch[0].id.concat(",", playerId);
        }
        else {
            chPlayer = "noActiveMatch".concat(",", playerId);
        }
        setChangedPlayer(chPlayer);
    }

    function setMatchActiveHandler(match) {
        setActiveMatch(match);
    }

    // useEffect to retrieve Players 
    useEffect(() => {
            setIsLoading(true);

            getPlayers(props.onError)
                .then((players) => {
                    setPlayers(players) 
                }).catch(error => {
                    let errMsg = "ERROR: getPlayers: " + error;
                    props.onError(errMsg);
                });

            setIsLoading(false);
    }, []);


    // Start building page content
    let womenTwoFive = [];
    let womenThreeZero = [];
    let womenThreeFive = [];
    let menTwoFive = [];
    let menThree = [];
    let menThreeFive = []

    if (players) {
       womenTwoFive = getPlayersForGenderAndLevel(players, constDefs.female, constDefs.twoFive);
       womenThreeZero = getPlayersForGenderAndLevel(players, constDefs.female, constDefs.threeZero);
       womenThreeFive = getPlayersForGenderAndLevel(players, constDefs.female, constDefs.threeFive);
       menTwoFive = getPlayersForGenderAndLevel(players, constDefs.male, constDefs.twoFive);
       menThree = getPlayersForGenderAndLevel(players, constDefs.male, constDefs.threeZero);
       menThreeFive = getPlayersForGenderAndLevel(players, constDefs.male, constDefs.threeFive);
    }

    // Matches content
    let matchesContent = "";

    if (hasRead) {
        matchesContent = (
            <LineupMatches 
                activeMatch={activeMatch} 
                changedPlayer={changedPlayer} 
                getPlayerNameById={getPlayerNameById}
                getPlayerById={getPlayerById}
                onSetMatchActive={setMatchActiveHandler} 
                onSetChangedPlayer={setChangedPlayerHandler}
                readOnly={readOnly}
            />
        )
    }
        
    // Begin banner content
    let banner = <div>*** Lineups below are not final! ***</div>

    // Begin content setup
    let content = <p>No players found.</p>;

    if (isLoading) {
        content = <p>Loading...</p>;
    }

    // Set up content for rendering
    content = (
        <div>
            <div className={classes.componentSection}> 
                <div className={classes.leftSideMatchesLabel}>
                    <div>M</div>
                    <div>A</div>
                    <div>T</div>
                    <div>C</div>
                    <div>H</div>
                    <div>E</div>
                    <div>S</div>
                </div>
                {matchesContent}
            </div>
        </div>
    );

    return (
        <div>
            <div className={classes.banner}>{banner}</div>
            <div className={classes.lineupLayout}>  
                {content}
            </div>
        </div>

    ); 
}

export default Lineup;