import MatchInfo from './MatchInfo';
import classes from './Matches.module.css';

function Matches({ matchList}) {
    return (
        <div className={classes.matchesLayout}>
            <div className={classes.matchInfoBlock}>
                <div className={classes.titleMatchInfoMatches}>Matches</div>               
                <div className={classes.titleMatchInfoPlayers}>Players</div>               
            </div>
            {matchList.map((match) => (
                <div className={classes.matchesDetail} key={match.id}>
                   <MatchInfo matchInfo={{match}} />
               </div>
            ))}
        </div>
    ); 
}

export default Matches;
            //<div className={classes.scrollBarFiller}>H</div>