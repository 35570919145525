
import { useState, useEffect, useRef } from 'react';
import { updateMatches } from '../dataAccess/getData';
import { postToGroupMe } from '../dataAccess/getData';
import * as constDefs from '../constants/constants';
import LineupMatch from './LineupMatch';
import {getMatches } from '../dataAccess/getData';
import classes from './LineupMatches.module.css';

//Component
function LineupMatches(props) {
    const [matchLineups, setMatchLineups] = useState();
    const [isLoading, setIsLoading] = useState(false);

    function getPlayersAvailabilityForGenderAndLevel(players, gender, level) {
        return players.filter((player) => player.gender === gender && player.rating === level);
    };

    function getMatchById(id) {
        return matchLineups.filter((match) => match.id === id);
    }

    function setMatchActiveHandler(e) {
        let matchId = e.target.getAttribute("data-matchid");
        let match= getMatchById(matchId);
        props.onSetMatchActive(match);

    }

    function allowDropHandler(e) {
        let allowDrop = true;

        let matchId = e.target.getAttribute("data-matchid");
        let match= getMatchById(matchId);

        let changedPlayerObj = e.target.getAttribute("changedplayer-id");
        let changedPlayerArr = changedPlayerObj.split(",");
        let changedPlayerMatchId = changedPlayerArr[0];
        let changedPlayerId = changedPlayerArr[1];

        var evArray = e.target.id.split(",");
        var courtPos = evArray[0];
        let playerId = evArray[1];
        
        //Note: If playerId is truthy, then a player is already assigned this slot.

        //Edit: If a player is already in this drop slot, do not allow a drop.
        if (playerId) {
            allowDrop = false;
        }

        //Edit: If the match is not active, do not allow the drop.
        if (changedPlayerMatchId !== matchId) {
            allowDrop = false;
        }

        //Edits
        if (!playerId && allowDrop) {
            Object.entries(match[0].match_assignments).forEach((courtPlayer) => {

                //Edit: Don't allow drop if player is already assigned a court for active match
                if (changedPlayerId === courtPlayer[1]) {
                    allowDrop = false;
                }

                if (allowDrop) {
                    if (courtPos.slice(0,6) === courtPlayer[0].slice(0,6)) {
                        if (changedPlayerId != courtPlayer[1]) {
                            let draggedPlayer = props.getPlayerById(changedPlayerId);
                            let otherPlayer = props.getPlayerById(courtPlayer[1]);

                            //Edit: Dont' allow drop if another player is assigned the court and genders are equal.
                            if (otherPlayer[0]) {
                                if (draggedPlayer[0].gender === otherPlayer[0].gender) {
                                    allowDrop = false;
                                }

                                if (allowDrop) {
                                    //Edit: Don't allow drop if another player is assigned the court and the ratings are greater than 6.0
                                    let draggedPlayerRating = parseFloat(draggedPlayer[0].rating);
                                    let otherPlayerRating = parseFloat(otherPlayer[0].rating);
                                    if ((draggedPlayerRating + otherPlayerRating) > constDefs.maxRating) {
                                        allowDrop = false;
                                    }
                                }
                            }
                        }
                    }
                }
            })
        }

        //console.log("target: ", e.target.id);

        if (allowDrop) {
            e.stopPropagation();
            e.preventDefault();
        }

    }

    function setPlayerHandler(ev) {
        ev.preventDefault();

        let playerArr = ev.target.id.split(",");
        let playerId = playerArr[1];
        let courtPos = playerArr[2];
        let matchId = ev.target.getAttribute("data-matchid");
        let match = getMatchById(matchId)[0];

        Object.entries(match.match_assignments).forEach((courtPlayer) => {
            var key = courtPlayer[0];
            if (key == courtPos) {
                match.match_assignments[key] = playerId;
            }
        })

        matchLineups.forEach((matchLineup) => {
            if (matchLineup.id === match.id) {
                matchLineup.match_assignments = match.match_assignments;
            }
        })

        try {
            // Call post
            let returnVal = updateMatches(matchLineups, props.onError);
            // Update state
            setMatchLineups(matchLineups);
            props.onSetChangedPlayer(ev.target.id);
        }
        catch(error) {
            props.onError(error);
        }
    }

    function removePlayerHandler(ev) {
        let playerIdIdx = ev.target.id.indexOf(",");
        let courtPos = ev.target.id.slice(0,playerIdIdx);
        let playerToRemove = ev.target.id.slice(playerIdIdx+1);
        let matchId = ev.target.getAttribute("data-matchid");
        let match = getMatchById(matchId)[0];

        Object.entries(match.match_assignments).forEach((courtPlayer) => {
            if ((courtPlayer[0] === courtPos) && (courtPlayer[1] === playerToRemove)) {
                match.match_assignments[courtPlayer[0]] = "";
            }
        })

        matchLineups.forEach((matchLineup) => {
            if (matchLineup.id === match.id) {
                matchLineup.match_assignments = match.match_assignments;
            }
        })

        try {
            // Call post
            let returnVal = updateMatches(matchLineups, props.onError);
            // Update state
            setMatchLineups(matchLineups) 
            props.onSetChangedPlayer(matchId + "," + courtPos);
        }
        catch(error) {
            props.onError(error);
        }
    }

    function postToGroupMeHandler(id, groupMeText) {

        let matchObj = {
            "matchId": id,
            "message": groupMeText 
        };

        postToGroupMe(matchObj)
            .then((returnVal) => {
                alert("GroupMe Posted!");
            }).catch(error => {
                let errMsg = "ERROR: getMatches: " + error;
                console.log(errMsg);
                props.onError(errMsg);
            });
    }

    // useEffect to retrieve Players and Availability
    useEffect(() => {
            setIsLoading(true);

            getMatches(props.onError)
                .then((matchesContent) => {
                    setMatchLineups(matchesContent) 
                }).catch(error => {
                    let errMsg = "ERROR: getMatches: " + error;
                    console.log(errMsg);
                    props.onError(errMsg);
                });

            setIsLoading(false);
    //}, [matchLineups]);
    }, []);

    // Begin content setup
    let content = <p>No matches found.</p>;

    if (isLoading) {
        content = <p>Loading...</p>;
    }

    // Set up content for rendering
    if (matchLineups) {
        let matchesLayout = classes.matchesLayout;
        if (props.readOnly) {
            matchesLayout = classes.matchesLayoutRO;
        } 

        content = (
            <div className={matchesLayout}>
                    {matchLineups.map((match) => (
                        <div key={match.id}>
                            <LineupMatch 
                                matchInfo={match} 
                                isActive={props.activeMatch && props.activeMatch.length > 0 && props.activeMatch[0].id === match.id} 
                                changedPlayer={props.changedPlayer} 
                                getPlayerNameById={props.getPlayerNameById}
                                readOnly={props.readOnly}
                                onSetMatchActive={setMatchActiveHandler} 
                                onSetAllowDrop={allowDropHandler} 
                                onSetOnDrop={setPlayerHandler} 
                                onSetRemovePlayer={removePlayerHandler}
                                onPostToGroupMe={postToGroupMeHandler}
                                />
                        </div>
                    ))}
            </div>
        );
    }

    return (
        <div> 
            {content}
        </div>
    ); 
}

export default LineupMatches;