/**********************************************
 * Shared functions
 *********************************************/

//* This function compares two objects with the keyword of 'id'
export function compare(a, b) {
    /*
    if (a.id.charCodeAt(0) < b.id.charCodeAt(0)) {
        return -1;
    }
    if (a.id.charCodeAt(0) > b.id.charCodeAt(0)) {
        return 1;
    }
    */
    if (parseInt(a.id) < parseInt(b.id)) {
        return -1;
    }
    if (parseInt(a.id) > parseInt(b.id)) {
        return 1;
    }
    return 0;
}

export function compareMatchId(a, b) {
    /*
    if (a.id.charCodeAt(0) < b.id.charCodeAt(0)) {
        return -1;
    }
    if (a.id.charCodeAt(0) > b.id.charCodeAt(0)) {
        return 1;
    }
    */
    if (parseInt(a.matchId) < parseInt(b.matchId)) {
        return -1;
    }
    if (parseInt(a.matchId) > parseInt(b.matchId)) {
        return 1;
    }
    return 0;
}